import React, { useState, useEffect } from "react"
import { parseCookies } from 'nookies'

import { Button } from "components/anti/buttons/buttons"
import Layout from "components/layout"
import { navigate } from "gatsby"
import { NumFormat } from "components/anti/num-format/num-format"
import Seo from "components/seo"


const BpjsPaymentConfirm = () => {
    const cookies = parseCookies()
    const [detailPayment, setDetailPayment] = useState()


    useEffect(() => {

        if (cookies.detailPayment && cookies.authToken) {
            setDetailPayment(JSON.parse(cookies.detailPayment))
        } else {
            navigate('/bpjskesehatan/payment')
        }
    }, [])

    return (
        <Layout>
            <Seo title="Confirmation" />
            <section className="py-main">
                <div className="container mw-sm">
                    <Button
                        variant="link"
                        className="btn-back-help ai-arrow-from-left mb-3"
                        link={"/bpjskesehatan/payment"}
                    >
                        Kembali
                    </Button>
                    <h1>Pembayaran BPJS Kesehatan</h1>
                    <div className="mt-5 animated fadeInDown delayp5">
                        <p className="caption bg-light  py-2">Detail pembayaran</p>
                        <div className=" mb-5">
                            <div className="mb-4">
                                <p className="caption mb-0">Nomor Va Kepala Keluarga</p>
                                <p className="mb-4">{detailPayment?.noVaKepalaKeluarga}</p>
                            </div>
                            <div className="mb-4">
                                <p className="caption mb-0">Nomor Va</p>
                                <p className="mb-4">{detailPayment?.noVa}</p>
                            </div>
                            <div className="mb-4">
                                <p className="caption mb-0">Nama</p>
                                <p className="mb-4">{detailPayment?.namaPeserta}</p>
                            </div>
                            {/* <div className="mb-4">
                                <p className="caption mb-0">Nama Cabang</p>
                                <p className="mb-4">Jakarta Pusat (Masih hardcode ga ada di API)</p>
                            </div> */}
                            <div className="mb-4">
                                <p className="caption mb-0">Jumlah Anggota Keluarga</p>
                                <p className="mb-4">{detailPayment?.jumlahAnggota}</p>
                            </div>
                            <div className="mb-4">
                                <p className="caption mb-0">Jumlah Periode Pembayaran</p>
                                <p className="mb-4">{detailPayment?.jumlahPeriode}</p>
                            </div>
                        </div>
                        <p className="caption bg-light  py-2">Tagihan</p>
                        <div className="mb-4 ">
                            <p className="caption mb-0">Biaya Iuran</p>
                            <p className="mb-4">Rp{NumFormat(detailPayment?.biayaIuran)}</p>
                            <p className="caption mb-0">Biaya Admin</p>
                            <p className="mb-4">Rp{NumFormat(detailPayment?.biayaAdmin)}</p>
                            <p className="caption mb-0">Nominal yang perlu dibayar</p>
                            <h2 className="mb-4">Rp{NumFormat(detailPayment?.totalBayar)}</h2>
                        </div>
                        <div className="">
                            <Button variant="primary" className="btn-block" link={`/bpjskesehatan/payment/method`}>
                                Lanjut
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}


export default BpjsPaymentConfirm